//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { whitePremiumStar } from '~/static/icons';
export default {
    name: 'DownloadAppNudgeBanner',
    data() {
        return {
            whitePremiumStar
        };
    }
};
